import React, { useState } from "react"
import { countryList, industryList, roleList } from "../../utils/registration"
import { storeUserData } from "../../utils/backend"

export default function UserRegistration({
    visible, 
    userName, 
    bearerToken, 
    setIsLoading, 
    setIsLoadingText, 
    setPage, 
    setJustRegistered
}) {
    const [step, setStep] = useState("1")

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [country, setCountry] = useState("")
    const [industry, setIndustry] = useState("")
    const [industryOther, setIndustryOther] = useState("")
    const [role, setRole] = useState("")
    const [roleOther, setRoleOther] = useState("")

    // Check that all fields are filled in
    const isFormValid = () => {
        if (
            firstName.trim() === "" ||
            lastName.trim() === "" ||
            companyName.trim() === "" ||
            country.trim() === "" ||
            industry.trim() === "" ||
            role.trim() === ""
        ) {
            if (industry === "Other" && industryOther.trim() === "") {
                return false
            }
            if (role === "Other" && roleOther.trim() === "") {
                return false
            }
            return false
        }
        return true
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isFormValid()) {
            // Handle form submission
            let userData = {
                firstName,
                lastName,
                companyName,
                country,
                industry,
                industryOther,
                role,
                roleOther,
                email: userName
            }
            
            // Send data to server
            storeUserData(bearerToken, userData, setPage, setIsLoading, setIsLoadingText, setJustRegistered);
        }
    }

    if (!visible) {
        return null
    }

    if (step === "1") {
        return (
            <div className="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-black/90">
                <div className="w-1/2 space-y-10 divide-y divide-gray-900/10">
                    <form className="bg-zinc-800 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-8 py-10 sm:p-8">
                            <div
                                className="text-3xl text-white font-bold"
                            >
                                Welcome to the New Motorica!
                            </div>
                            <p className="mt-6 text-md text-gray-300">
                                <b>We’ve Upgraded!</b> Motorica is now cloud-based with all new and improved features.
                            </p>
                            <p className="mt-6 text-md text-gray-300">
                                If you’re a <b>new user</b>, welcome! Please complete the form to set up your new account and start enjoying Motorica.
                            </p>
                            <p className="mt-6 text-md text-gray-300">
                                <b>Already a user?</b> Please complete the form to ensure a seamless transfer and continued access to Motorica’s new platform.
                            </p>
                            <p className="mt-4 text-md text-gray-300">
                                <b>Having issues?</b> Try switching back to our old platform by clicking the user menu in the top right corner and selecting “Old Platform”.
                            </p>                        
                        </div>

                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-1 sm:px-4">
                            <button
                                type="submit"
                                className="mb-3 rounded-md bg-orange-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 disabled:bg-gray-600 cursor-pointer"
                                onClick={() => setStep("2")}
                            >
                                Continue
                            </button>
                        </div>                    
                    </form>
                </div>
            </div>
        )
    }

    if (step === "2") {
        return (
            <div className="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-black/90">
                <div className="w-1/2 space-y-10 divide-y divide-gray-900/10">
                    <form className="bg-zinc-800 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-8 py-10 sm:p-8">
                            <div
                                className="text-3xl text-white font-bold"
                            >
                                We would like to know a litte more about you.
                            </div>
                            <p className="mt-2 mb-10 text-md text-gray-300">
                                Please fill in the form below to complete your registration and to start using the new Motorica platform.
                            </p>

                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-300">
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-300">
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-300">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:max-w-xs sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        >
                                            <option value="" disabled defaultValue>Select a country...</option>
                                            {countryList.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-300">
                                        Company Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="company"
                                            id="company"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-300">
                                        Industry
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="industry"
                                            name="industry"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:max-w-xs sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={industry}
                                            onChange={(e) => setIndustry(e.target.value)}
                                        >
                                            <option value="" disabled defaultValue>Select an industry...</option>
                                            {industryList.map((industry) => (
                                                <option key={industry.key} value={industry.caption}>
                                                    {industry.caption}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className={industry === "Other" ? "" : " hidden"}>
                                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                        Please specify industry...
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="industryOther"
                                                id="industryOther"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6 bg-zinc-600"
                                                value={industryOther}
                                                onChange={(e) => setIndustryOther(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-300">
                                        Role
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="role"
                                            name="role"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:max-w-xs sm:text-sm sm:leading-6 bg-zinc-600"
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            placeholder="Select Role..."
                                        >
                                            <option value="" disabled defaultValue>Select a role...</option>
                                            {roleList.map((role) => (
                                                <option key={role.key} value={role.caption}>
                                                    {role.caption}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className={role === "Other" ? "" : " hidden"}>
                                        <label htmlFor="roleOther" className="block text-sm font-medium leading-6 text-gray-300">
                                            Please specify role...
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="roleOther"
                                                id="roleOther"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-300 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6 bg-zinc-600"
                                                value={roleOther}
                                                onChange={(e) => setRoleOther(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="submit"
                                className="mb-3 rounded-md bg-orange-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 disabled:bg-gray-600"
                                disabled={!isFormValid()}
                                onClick={handleSubmit}
                            >
                                Complete registration
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}