import React, { useEffect, useState } from "react"
import { useCookies } from 'react-cookie'

import { handleAuth } from "./utils/ory"
import { oryJwtTokenTtl, ENV_TYPE } from "./utils/config"

import NavBar from "./components/NavBar"
import LoaderScreen from "./components/LoaderScreen"

import UserRegistration from "./pages/UserRegistration"
import ThreeJsViewer from "./pages/ThreeJs"

const navigation = []

function App() {
  const [session, setSession] = useState()
  const [logoutUrl, setLogoutUrl] = useState()
  const [cookies, setCookie] = useCookies(['access_token'])
  const [page, setPage] = useState("main")
  const [justRegistered, setJustRegistered] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingText, setIsLoadingText] = useState("Loading...")

  // Returns either the email or the username depending on the user's Identity Schema
  const getUserName = (identity) =>
    identity?.traits.email || identity?.traits.username

  // Second, gather session data, if the user is not logged in, redirect to login
  useEffect(() => {
    handleAuth(setSession, setCookie, setLogoutUrl, setIsLoading, setIsLoadingText, page, setPage, justRegistered)

    const interval = setInterval(() => {
      handleAuth(setSession, setCookie, setLogoutUrl, setIsLoading, setIsLoadingText, page, setPage, justRegistered)
    }, 1000 * 60 * oryJwtTokenTtl);

    return () => clearInterval(interval);
  }, [page, setCookie, justRegistered])

  return (
    <div className="min-h-full">
      <NavBar
        navigation={navigation}
        userName={getUserName(session?.identity)}
        logoutUrl={logoutUrl}
      />

      <LoaderScreen
        visible={!session || isLoading}
        text={isLoadingText}
      />

      {page === "main" &&
        <ThreeJsViewer
          visible={!isLoading && session}
          userName={getUserName(session?.identity)}
          envType={ENV_TYPE ? ENV_TYPE : "dev"}
          cookies={cookies}
        />
      }

      {page === "register" &&
        <UserRegistration
          visible={!isLoading && session}
          bearerToken={session && session.tokenized}
          userName={getUserName(session?.identity)}
          setIsLoading={setIsLoading}
          setIsLoadingText={setIsLoadingText}
          setPage={setPage}
          setJustRegistered={setJustRegistered}
        />
      }
    </div>
  )
}

export default App